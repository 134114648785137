/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: 'ProductSans'; /* Use the same name as defined in Tailwind */
    src: url('../fonts/ProductSans/Product\ Sans\ Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    /* Add more src entries for different file formats if necessary */
}
@font-face {
    font-family: 'ProductSans'; /* Use the same name as defined in Tailwind */
    src: url('../fonts/ProductSans/Product\ Sans\ Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    /* Add more src entries for different file formats if necessary */
}
@font-face {
    font-family: 'ProductSans'; /* Use the same name as defined in Tailwind */
    src: url('../fonts/ProductSans/Product\ Sans\ Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    /* Add more src entries for different file formats if necessary */
}
@font-face {
    font-family: 'ProductSans'; /* Use the same name as defined in Tailwind */
    src: url('../fonts/ProductSans/Product\ Sans\ Bold\ Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    /* Add more src entries for different file formats if necessary */
}

.btn-available-width{
    width: -webkit-fill-available;
}

hg-icon {
    min-width: 16px;
    min-height: 16px;
}
  